import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";

const LightboxContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  height: 100%;

  padding: 30px;

  cursor: pointer;

  @media (max-width: 768px) {
    padding: 20px;
  }

  overflow-y: scroll;

  & > div {
    width: 100%;
    height: 100%;

    & .lightbox-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      position: relative;

      width: 100%;
      height: calc(100% - 33vh - 30px);
      margin: calc(33vh - 21px) 0 0 0;

      @media (max-width: 768px) {
        height: calc(100% - 33vh - 20px);
        margin: calc(33vh + 2px) 0 0 0;
      }

      & .top-row {
        width: 100%;

        & .about-text {
          & p {
            &:first-of-type {
              margin: 0 0 1em 0;
            }
          }

          & a {
            &:hover {
              font-family: "practice-book-italic", "Helvetica Neue",
                "Lucida Grande", sans-serif;
              font-style: normal;
            }
          }
        }

        & .links-container {
          margin: 0 0 2em 0;
        }
      }

      & .bottom-row {
        width: 100%;
        align-self: flex-end;

        & p {
          font-size: 14px;
          line-height: 17px;
        }
      }

      & .top-row,
      & .bottom-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @media (max-width: 768px) {
          display: block;
          grid-template-columns: unset;
        }
      }

      & .lightbox-content-container {
        grid-column: 1;

        @media (max-width: 1220px) {
          grid-column: 1 / 3;
        }
      }

      & .grid {
        display: grid;
        grid-template-columns: 65px auto;
        grid-column-gap: 30px;

        & p {
          margin: 0;
        }
      }

      & .flex {
        display: flex;

        & p {
          margin: 0;
        }
      }
    }
  }
`;

Modal.setAppElement("#___gatsby");

export const About = ({ isAboutOpen, setIsAboutOpen }) => {
  const height = use100vh();

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", event => onEscape(event));
    }
  }, [isAboutOpen]);

  const onEscape = event => {
    if (event.keyCode === 27 && isAboutOpen === true) {
      setIsAboutOpen(false);
    }
  };

  const data = useStaticQuery(graphql`
    {
      prismicAbout {
        data {
          text {
            html
          }
          links {
            link_title
            link {
              html
            }
          }
          credits {
            credit_title
            credit_link {
              html
            }
          }
        }
      }
    }
  `);

  const links = data.prismicAbout.data.links.map((link, index) => (
    <li key={`single_social_link_${index}`}>
      <div className="grid">
        <p className="link-title">{link.link_title}</p>

        <div
          className="link-text"
          dangerouslySetInnerHTML={{
            __html: link.link.html,
          }}
        />
      </div>
    </li>
  ));

  const credits = data.prismicAbout.data.credits.map((credit, index) => (
    <li key={`single_credits_link_${index}`}>
      <div className="flex">
        <p className="credit-title">{credit.credit_title}&nbsp;</p>
        <div
          className="credit-text"
          dangerouslySetInnerHTML={{
            __html: credit.credit_link.html,
          }}
        />
      </div>
    </li>
  ));

  return (
    <Modal
      isOpen={isAboutOpen}
      className="about-lightbox-modal"
      contentLabel={`About`}
      shouldFocusAfterRender={true}
      onRequestClose={() => setIsAboutOpen(false)}
    >
      <LightboxContainer height={height} onClick={() => setIsAboutOpen(false)}>
        <div className="container">
          <div className="header">
            <h1>
              <button onClick={() => setIsAboutOpen(false)}>Ted Targett</button>
            </h1>
          </div>

          <div className="lightbox-wrapper">
            <div className="top-row">
              <div className="lightbox-content-container">
                <div
                  className="about-text"
                  dangerouslySetInnerHTML={{
                    __html: data.prismicAbout.data.text.html,
                  }}
                />
                <ol className="links-container">{links}</ol>
              </div>
            </div>
            <div className="bottom-row">
              <div className="lightbox-content-container">
                <ol className="credits-container">{credits}</ol>
              </div>
            </div>
          </div>
        </div>
      </LightboxContainer>
    </Modal>
  );
};
