import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Preview
import { withPreview } from "gatsby-source-prismic";

// Icons
import { Square, Circle, Triangle } from "../components/icons/icons";

// Components
import { Lightbox } from "../components/images/lightbox";

const Page = styled.div`
  position: relative;
  z-index: 10;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  padding: 30px;

  z-index: 11000;

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;

    padding: 20px;
  }

  & .content {
    margin: 33vh 0 0 0;
    grid-column: 1 / 3;

    @media (max-width: 1220px) {
      grid-column: 1/4;
    }

    @media (max-width: 768px) {
      grid-column: unset;
    }
  }

  & .title-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    background-color: #fff;
    padding: 29px 30px 0 30px;
    width: calc(66vw + 34px);

    @media (max-width: 1220px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      padding: 20px 20px 0 20px;
    }

    & .grid-container {
      display: grid;
      grid-template-columns: 2fr 1fr;

      border-top: 1px solid #000;

      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 500px) {
        grid-template-columns: minmax(165px, 205px) auto;
        grid-column-gap: 15px;
      }

      & .category-title {
        display: grid;
        grid-template-columns: 90px auto;

        @media (max-width: 768px) {
          display: block;
          grid-template-columns: unset;
        }
      }

      & .border {
        grid-column: 1 / 3;
        border-bottom: 1px solid #000;
        margin: 0 0 0 90px;

        @media (max-width: 768px) {
          margin: 0;
        }
      }
    }

    & .title {
      display: block;

      padding: 2px 3px;
    }

    & .category {
      padding: 2px 0;
    }

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;

      & .title {
        border-bottom: 0;
      }

      & .category {
        display: none;
      }
    }

    & h1,
    & p {
      margin: 0;
      line-height: 21px;

      @media (max-width: 768px) {
        line-height: 20px;
      }
    }

    & .details {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr;

      padding: 2px 3px 3px 3px;

      @media (max-width: 768px) {
        display: block;
        grid-template-columns: unset;

        text-align: right;

        border-bottom: 0;

        & .type {
          display: none;
        }
      }
    }
  }

  & .grid-content-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;

    margin: 0 0 1em 0;

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    &.text-module {
      margin: 0 0 1em 0;

      & p {
        margin: 0;
        text-indent: 2em;
        text-align: justify;

        &:first-of-type {
          text-indent: 0;
        }
      }
    }
  }

  & .text-container {
    grid-column: 1 / 3;
  }

  & .image-container {
    margin: 4px 0 0 0;

    & img {
      cursor: pointer;
    }

    &.image-width-full {
      grid-column: 1 / 3;
    }

    &.image-width-half {
      grid-column: 1 / 2;
    }

    & .caption {
      font-size: 14px;
      line-height: 17px;

      & p {
        margin: 5px 0 0 0;
      }
    }
  }

  & .footnote-module {
    grid-column: 1 / 3;
    margin: 30px 0 0 0;

    & ol {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;
      grid-column: 1 / 3;

      & li {
        display: flex;
        margin-bottom: 0.5em;
        margin-left: 2em;

        font-size: 14px;
        line-height: 17px;

        margin: 0 0 5px 18px;

        & .text-container {
          padding: 0 0 0 26px;
        }

        & p {
          margin: 0;
        }

        counter-increment: custom;

        &:before {
          content: counter(custom) " ";
          margin: 0 0 5px -18px;
        }
      }

      @media (max-width: 768px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }
    }
  }
`;

const Footer = styled.div`
  grid-column: 1 / 3;
  margin: 60px 0 0 0;

  & .navigation-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      grid-template-columns: unset;
      grid-column-gap: unset;

      & .next-article-link {
        order: 1;
        flex: 100%;
      }

      & .homepage-link {
        order: 12;
        flex: 100%;
      }
    }
  }

  & .grid {
    display: grid;
    grid-template-columns: 10px auto;
    align-items: center;

    @media (max-width: 768px) {
      align-items: flex-start;
    }
  }

  & .credit-container {
    margin: 0 0 7px 0;

    & p {
      margin: 0;
    }
  }

  & svg {
    width: 7px;
    height: 7px;

    margin: 1px 0 0 0;

    @media (max-width: 768px) {
      margin: 7px 0 0 0;
    }
  }
`;

const Project = ({ data }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Next Project Links
  const writingProjects = data.prismicHome.data.writing_projects
    .filter(project => project.project.document !== null)
    .map(project => project.project.document.uid);

  const curatingProjects = data.prismicHome.data.curating_projects
    .filter(project => project.project.document !== null)
    .map(project => project.project.document.uid);

  // Next writing project link
  const currentWritingProjectPageNumber = writingProjects.indexOf(
    data.prismicProject.uid
  );
  const nextWritingProjectUid =
    currentWritingProjectPageNumber + 1 < writingProjects.length
      ? writingProjects[currentWritingProjectPageNumber + 1]
      : writingProjects[0];

  // Next curating project link
  const currentCuratingProjectPageNumber = curatingProjects.indexOf(
    data.prismicProject.uid
  );
  const nextCuratingProjectUid =
    currentCuratingProjectPageNumber + 1 < curatingProjects.length
      ? curatingProjects[currentCuratingProjectPageNumber + 1]
      : curatingProjects[0];

  const content = data.prismicProject.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <div
          className="grid-content-container text-module"
          key={`single_text_module_${index}`}
        >
          <div
            className="text-container"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === "image") {
      return (
        <div
          className={`grid-content-container image-module image-width-${content.primary.width.toLowerCase()}`}
          key={`single_image_module_${index}`}
        >
          <div
            className={`image-container image-width-${content.primary.width.toLowerCase()}`}
          >
            {content.primary.image.fluid !== null && (
              <img
                srcSet={content.primary.image.fluid.srcSetWebp}
                src={content.primary.image.fluid.srcWebp}
                alt={content.primary.image.alt}
                loading="lazy"
                onClick={() => {
                  setSelectedImage(content.primary.image);
                  setIsLightboxOpen(true);
                }}
              />
            )}
            <div
              className="caption"
              dangerouslySetInnerHTML={{
                __html: content.primary.caption.html,
              }}
            />
          </div>
        </div>
      );
    }

    if (content.slice_type === "footnotes") {
      const footnotes = content.items.map((footnote, innerIndex) => (
        <li key={`single_footnote_${innerIndex}`}>
          <div
            className="text-container"
            dangerouslySetInnerHTML={{
              __html: footnote.footnote.html,
            }}
          />
        </li>
      ));

      return (
        <div
          className="grid-content-container footnote-module"
          key={`single_footnote_module_${index}`}
        >
          <ol>{footnotes}</ol>
        </div>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicProject.data.title.text} | Ted Targett`}
        meta={[
          {
            name: "title",
            content: `${data.prismicProject.data.title.text} | Ted Targett`,
          },
          {
            property: "og:title",
            content: `${data.prismicProject.data.title.text} | Ted Targett`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicProject.data.title.text} | Ted Targett`,
          },
          {
            property: "og:url",
            content: `https://tedtargett.co.uk${data.prismicProject.url}`,
          },
          {
            property: "twitter:url",
            content: `https://tedtargett.co.uk${data.prismicProject.url}`,
          },
        ]}
      >
        <link
          rel="canonical"
          href={`https://tedtargett.co.uk${data.prismicProject.url}`}
        />
      </Helmet>

      <Page>
        <div className="title-container">
          <div className="grid-container">
            <div className="category-title">
              <p className="category">
                <Link to={`/`}>{data.prismicProject.data.category}</Link>
              </p>
              <Link to={`/`} className="title">
                <h1>{data.prismicProject.data.title.text}</h1>
              </Link>
            </div>

            <div className="details">
              <p className="type">{data.prismicProject.data.type}</p>
              <p className="year">{data.prismicProject.data.year}</p>
              <p className="client">{data.prismicProject.data.client}</p>
            </div>

            <div className="border" />
          </div>
        </div>

        <div className="content">{content}</div>

        <Footer>
          {data.prismicProject.data.credit.text.length >= 1 && (
            <div className="credit-container grid">
              <Circle />
              <div
                className="credit-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicProject.data.credit.html,
                }}
              />
            </div>
          )}

          <div className="navigation-container">
            <div className="link-container homepage-link">
              <Link to={`/`} className="grid">
                <Triangle /> Back to Homepage
              </Link>
            </div>

            <div className="link-container next-article-link">
              <Link
                to={
                  data.prismicProject.data.category === "Writing"
                    ? `/${nextWritingProjectUid}/`
                    : `/${nextCuratingProjectUid}/`
                }
                className="grid"
              >
                <Square /> Next Article
              </Link>
            </div>
          </div>
        </Footer>
      </Page>

      {isLightboxOpen && (
        <Lightbox
          image={selectedImage}
          isLightboxOpen={isLightboxOpen}
          setIsLightboxOpen={setIsLightboxOpen}
        />
      )}
    </>
  );
};

export default withPreview(Project);

export const query = graphql`
  query Project($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      uid
      url
      data {
        title {
          text
        }
        type
        year
        client
        category
        credit {
          html
          text
        }
        body {
          ... on PrismicProjectBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicProjectBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  width
                  height
                }
              }
              caption {
                html
              }
              width
            }
          }
          ... on PrismicProjectBodyFootnotes {
            id
            slice_type
            items {
              footnote {
                html
              }
            }
          }
        }
      }
    }
    prismicHome {
      data {
        writing_projects {
          project {
            document {
              ... on PrismicProject {
                id
                uid
              }
            }
          }
        }
        curating_projects {
          project {
            document {
              ... on PrismicProject {
                id
                uid
              }
            }
          }
        }
      }
    }
  }
`;
