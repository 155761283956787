import React from "react";
import styled from "styled-components";

const Icon = styled.svg``;

export const Square = () => (
  <Icon
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M270 30H30V270H270V30Z" fill="#1D1D1B" />
  </Icon>
);

export const Circle = () => (
  <Icon
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M149.8 285C224.3 285 285 224.7 285 150.2C285 75.7 224.3 15 149.8 15C75.3 15 15 75.7 15 150.2C14.9869 167.906 18.4646 185.441 25.2343 201.801C32.004 218.162 41.9328 233.027 54.4527 245.547C66.9727 258.067 81.8381 267.996 98.1988 274.766C114.559 281.535 132.094 285.013 149.8 285"
      fill="#1D1D1B"
    />
  </Icon>
);

export const Triangle = () => (
  <Icon
    width="251"
    height="300"
    viewBox="0 0 251 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.600098 149.5L250.6 299.5V-0.5L0.600098 149.5Z" fill="#1D1D1B" />
  </Icon>
);
