import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Preview
import { withPreview } from "gatsby-source-prismic";

// Components
import { About } from "../components/about/about";

const Page = styled.div`
  position: relative;
  z-index: 1;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  padding: 30px;

  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    padding: 30px;

    height: calc(33vh + 30px);
    background: #fff;
    z-index: 2;

    & .border-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column: 1 / 3;

      align-items: flex-end;
      height: calc(100% + 7px);

      & .border {
        grid-column: 1 / 3;
        position: relative;

        border-bottom: 1px solid #000;

        @media (max-width: 1220px) {
          grid-column: 1 / 4;
        }

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    @media (max-width: 768px) {
      position: relative;
      padding: 0;
      height: auto;
      background: transparent;
    }
  }

  & .content {
    margin: 33vh 0 60px 0;
    grid-column: 1 / 3;

    @media (max-width: 1220px) {
      grid-column: 1/4;
    }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;

    padding: 20px;

    & .content {
      margin: 33vh 0 0 0;
      grid-column: unset;
    }
  }
`;

const Section = styled.section`
  display: grid;
  grid-template-columns: 90px auto;

  margin: 0 0 24px 0;

  & .section-title {
    & h1 {
      line-height: 19px;
      padding: 3px 0;
    }
  }

  &:nth-of-type(2) {
    border-top: 1px solid #000;
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    margin: 0 0 40px 0;

    border-top: 0;

    & .section-title {
      border-bottom: 1px solid #000;
    }

    &:nth-of-type(2) {
      border-top: 0;
    }
  }
`;

const SingleProject = styled.li`
  background-color: #fff;
  border-bottom: 1px solid #000;
  transition: 250ms all ease;

  line-height: 19px;
  padding: 3px 2px;

  @media (max-width: 768px) {
    padding: 3px 0px;
  }

  & a {
    display: block;
  }

  & h2,
  & p {
    margin: 0;
    line-height: 19px;
  }

  & .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;

    @media (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 500px) {
      grid-template-columns: minmax(165px, 195px) auto;
      grid-column-gap: 15px;
    }

    & .details {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr;

      @media (max-width: 768px) {
        display: block;
        grid-template-columns: unset;

        text-align: right;

        & .type {
          display: none;
        }
      }
    }
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }

  @media (hover: none) {
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
`;

const Index = ({ data }) => {
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const Project = ({ project }) => (
    <Link to={project.project.document.url}>
      <div className="grid">
        <h2 className="title">{project.project.document.data.title.text}</h2>

        <div className="details">
          <p className="type">{project.project.document.data.type}</p>
          <p className="year">{project.project.document.data.year}</p>
          <p className="client">{project.project.document.data.client}</p>
        </div>
      </div>
    </Link>
  );

  const writingProjects = data.prismicHome.data.writing_projects
    .filter(project => project.project.document !== null)
    .map((project, index) => (
      <SingleProject
        key={`single_writing_project_${index}_${project.project.document.id}`}
      >
        <Project project={project} />
      </SingleProject>
    ));

  const curatingProjects = data.prismicHome.data.curating_projects
    .filter(project => project.project.document !== null)
    .map((project, index) => (
      <SingleProject
        key={`single_curating_project_${index}_${project.project.document.id}`}
      >
        <Project project={project} />
      </SingleProject>
    ));

  return (
    <>
      <Page>
        <div className="header">
          <h1>
            <button onClick={() => setIsAboutOpen(!isAboutOpen)}>
              Ted Targett
            </button>
          </h1>

          <div className="border-grid">
            {/* <div className="border-start" /> */}
            <div className="border" />
          </div>
        </div>

        <div className="content">
          <Section>
            <div className="section-title">
              <h1>Curating</h1>
            </div>

            <ol>{curatingProjects}</ol>
          </Section>

          <Section>
            <div className="section-title">
              <h1>Writing</h1>
            </div>
            <ol>{writingProjects}</ol>
          </Section>
        </div>
      </Page>
      <About isAboutOpen={isAboutOpen} setIsAboutOpen={setIsAboutOpen} />
    </>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    prismicHome {
      data {
        writing_projects {
          project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    text
                  }
                  type
                  year
                  client
                }
              }
            }
          }
        }
        curating_projects {
          project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    text
                  }
                  type
                  year
                  client
                }
              }
            }
          }
        }
      }
    }
  }
`;
