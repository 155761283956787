import React, { useEffect } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const LightboxContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  height: ${props => props.height}px;

  overflow: hidden;
  cursor: pointer;

  & > div {
    width: 100%;
    height: 100%;

    padding: 60px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 768px) {
      padding: 30px;
    }

    @media (max-width: 500px) {
      padding: 0;
    }
  }
`;

// const CloseLightbox = styled.button`
//   position: absolute;
//   top: 26px;
//   right: 30px;

//   @media (max-width: 768px) {
//     top: 16px;
//     right: 20px;
//   }

//   color: #000;

//   z-index: 100;

//   font-size: 25px;
//   line-height: 1;

//   cursor: pointer;

//   transition: 150ms color ease;

//   &:hover {
//     color: #6f6d6d;
//   }
// `;

Modal.setAppElement("#___gatsby");

export const Lightbox = ({ isLightboxOpen, setIsLightboxOpen, image }) => {
  const height = use100vh();

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", event => onEscape(event));
    }
  }, [isLightboxOpen]);

  const onEscape = event => {
    if (event.keyCode === 27 && isLightboxOpen === true) {
      setIsLightboxOpen(false);
    }
  };

  return (
    <Modal
      isOpen={isLightboxOpen}
      className="about-lightbox-modal"
      contentLabel={`About`}
      shouldFocusAfterRender={true}
      onRequestClose={() => setIsLightboxOpen(false)}
    >
      {/* <CloseLightbox onClick={() => setIsLightboxOpen(false)}>×</CloseLightbox> */}
      <LightboxContainer
        height={height}
        onClick={() => setIsLightboxOpen(false)}
      >
        <div className="container">
          <img
            className={ImageOrientation(image)}
            srcSet={image.fluid.srcSetWebp}
            src={image.fluid.srcWebp}
            alt={image.alt}
          />
        </div>
      </LightboxContainer>
    </Modal>
  );
};
